import styled, {keyframes} from 'styled-components';
import React from 'react';

  var RotateOne = keyframes`
    0% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
  `

  var RotateTwo = keyframes`
    0% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
  `

  var RotateThree = keyframes`
    0% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
  `
  
  const Loading = styled.div`
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
    background-color: #323332;`

  const InnerOne = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fffff;
    left: 0%;
    top: 0%;
    animation: ${RotateOne} 1s linear infinite;
    border-bottom: 3px solid #EFEFFA;`

  const InnerTwo = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%; 
    right: 0%;
    top: 0%;
    animation: ${RotateTwo} 1s linear infinite;
    border-right: 3px solid #EFEFFA;
  `

  const InnerThree = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    right: 0%;
    bottom: 0%;
    animation: ${RotateThree} 1s linear infinite;
    border-top: 3px solid #EFEFFA; 
  `

const LoadingReact = () => {
      return (
          <Loading >
              <InnerOne />
              <InnerTwo />
              <InnerThree />
          </Loading>
      )
  }

export default LoadingReact;