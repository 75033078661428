import React, {Component, Suspense, lazy} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Loading from './components/loading';

const Verify = lazy(() => import("./components/verify"));
const Home = lazy(() => import("./components/home"));
const Login = lazy(() => import("./components/login"));

class App extends Component {
  state = {
    isLogin: false,
    token: "14718c4668581806bdeabdb6e898686852e8d63a2e39940e26f0a183f252926f"
  }

  render() {
    const {isLogin, token} = this.state;
    let verify = () => {
      let ver = false;
      if(isLogin && token !== "") {
        ver = true;
      }
      return ver;
    }
    return (
      <BrowserRouter basename="">
        <Switch>
          <Route exact path='/' render={()=> <Suspense fallback={<Loading />}><Verify token={token}/></Suspense>} />
          <Route path='/app' render={()=> <Suspense fallback={<Loading />}><Home /></Suspense>} />
          <Route path='/login' render={()=> <Suspense fallback={<Loading />}><Login /></Suspense>} />
          <Redirect to ='/'/>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
